import "./Card.css";
import React, { useState , useRef  } from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import StarIcon from "@mui/icons-material/Star";
import { IMAGES_URL, IMAGES_URL2 } from "../Services/Config";
import { IconButton, Dialog, DialogContent, Snackbar, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import 'aframe';
import VideoPlayer from "../Components/VideoPlayer/VideoPlayer";


const MyCard = ({
  name,
  price,
  location,
  available_at,
  availability_ends_at,
  rating,
  distance,
  property_images,
  rent_buy,
  id,
  vr_url,  
  image360_url,  
  sponsorr,
}) => {
  const [vrDialogOpen, setVrDialogOpen] = useState(false);
  const [image360DialogOpen, setImage360DialogOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);

  const imagesWithBaseUrl = property_images.map((image) => {
    return `${IMAGES_URL}${image.image_url}`;
  });
  
  const imageUrl = imagesWithBaseUrl.length > 0 ? imagesWithBaseUrl[0] : "";
  const vrWithBaseUrl = vr_url ? `${IMAGES_URL2}${vr_url}` : "";
  // const image360WithBaseUrl = image360_url ? `${IMAGES_URL2}${image360_url}` : "";
  const [image360WithBaseUrl, setimage360WithBaseUrl] = useState(  image360_url ? `${IMAGES_URL2}${image360_url}` : ""  ); 

  const videoRef = useRef(null); // Create a ref for the video
  // const [image360Content, setImage360Content] = useState(); // State to manage dialog content


  const redirectToUrl = () => {
    const url = `/propertydetails?id=${id}`;
    window.location.href = url;
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  function formatRange(start, end) {
    if (rent_buy === 1) {
      return ""; // No date range for properties for sale
    }

    const startDate = new Date(start);
    const endDate = new Date(end);

    if (
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear()
    ) {
      return `${formatDate(start)} - ${endDate.getDate()}`;
    } else {
      return `${formatDate(start)} - ${formatDate(end)}`;
    }
  }

  const handleVrDialogOpen = (event) => {
    event.stopPropagation(); // Prevent card click handler from triggering
    if (!vr_url) {
      setNotificationOpen(true);
    } else {
      setVrDialogOpen(true);
    }
  };

  const handleImage360DialogOpen = (event) => {
    event.stopPropagation(); // Prevent card click handler from triggering
    if (!image360_url) {
      setNotificationOpen(true);

    } else {
      setImage360DialogOpen(true);
    }
  };

  const handleDialogClose = () => {

    // setimage360WithBaseUrl(null);
    
    setVrDialogOpen(false);
    setImage360DialogOpen(false);
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);

  };
  console.log("Property Details:", { rent_buy });
  return (
    <div>
      <Card
        className="cards"
        sx={{
          boxShadow: "none",
          "&:hover": {
            boxShadow: "rgb(116, 116, 116) 0px 2px 8px 0px;",
          },
        }}
        onClick={redirectToUrl}
      >
        <CardActionArea>
          <div style={{ position: 'relative' }}>
            <CardMedia
              component="img"
              height="240"
              image={imageUrl}
              alt="Property Image 1"
            />

  {/* Label indicating For Sale or For Rent */}
  <Typography
      variant="caption"
      style={{
        position: 'absolute',
        top: '8px',
        left: '8px',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: 'white',
        padding: '4px 8px',
        borderRadius: '4px',
        fontWeight: 'bold',
        zIndex: 1, // Ensure it appears above other elements
      }}
    >
      {rent_buy === 0 ? "For Rent" : "For Sale"}
    </Typography>

    {/* Sponsor label, only if sponsorr is 1 */}
    {sponsorr === 1 && (
      <Typography
        variant="caption"
        style={{
          position: 'absolute',
          top: '8px',
          right: '8px',
          backgroundColor: '#FFD700', // Gold color
          color: 'black',
          padding: '4px 8px',
          borderRadius: '4px',
          fontWeight: 'bold',
          fontSize: '0.75rem',
          border: '1px solid #FFC107', // Lighter gold border
          zIndex: 2, // Ensure it appears above other elements
        }}
      >
        Sponsor
      </Typography>
    )}

            <div style={{ position: 'relative', width: '100%' }}>
              <IconButton
                style={{
                  position: 'absolute',
                  bottom: '8px',
                  left: '8px',
                  backgroundColor: 'grey',
                  color: 'white',
                  borderRadius: '50%',
                  width: '60px',
                  height: '50px',
                  padding: '8px',
                }}
                onClick={handleVrDialogOpen}
              >
                VR
              </IconButton>

              <IconButton
                style={{
                  position: 'absolute',
                  bottom: '8px',
                  right: '8px',
                  backgroundColor: 'grey',
                  color: 'white',
                  borderRadius: '50%',
                }}
                onClick={handleImage360DialogOpen}
              >
                360
              </IconButton>
            </div>
          </div>

          <CardContent style={{ backgroundColor: "rgb(230, 230, 230)", height: '100%' }} className="whatever">
            <Typography variant="h6" component="div" style={{ fontFamily: "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ marginBottom: '0.5rem' }}>{location}</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <StarIcon style={{ fontSize: "1rem", verticalAlign: "middle" }} />
                  <span style={{ fontSize: "1rem", verticalAlign: "middle", marginLeft: "4px" }}>
                    {rating}
                  </span>
                </div>
              </div>
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{ fontFamily: "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif" }}>
              {rent_buy === 0 ? (
                <>
                  <span>{formatRange(available_at, availability_ends_at)}</span>
                  <br />
                </>
              ) : null}
              <span style={{ marginTop: "1rem" }}>{parseFloat(distance).toFixed(1)} KM(s) away</span>
              <br />
              <span style={{ marginTop: '0.5rem', display: 'block', fontWeight: "bold", wordWrap: 'break-word', whiteSpace: 'normal' }}>
                Price: 
                {rent_buy === 0 ? (
                  <span>${price} / Night</span>
                ) : rent_buy === 1 ? (
                  <span>${price}</span>
                ) : null}
              </span>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>

      <Dialog
        open={image360DialogOpen}
        onClose={handleDialogClose}
        maxWidth="md"
        fullWidth={true}
        PaperProps={{
          style: {
            width: '80%',
            height: '80%',
            maxWidth: 'none',
            maxHeight: 'none',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            position: 'relative',
          },
        }}
      >
        <DialogContent style={{ padding: 0, height: '100%', backgroundColor: 'transparent' }}>
        <VideoPlayer url={image360WithBaseUrl} playing={true} controls={true} />

          {/* <a-scene embedded>
            <a-videosphere ref={videoRef} src={image360WithBaseUrl}></a-videosphere>
            


          </a-scene> */}

        </DialogContent>
        <IconButton
          style={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1300,
          }}
          onClick={handleDialogClose}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Dialog
        open={vrDialogOpen}
        onClose={handleDialogClose}
        maxWidth="md"
        fullWidth={true}
        PaperProps={{
          style: {
            width: '80%',
            height: '80%',
            maxWidth: 'none',
            maxHeight: 'none',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            position: 'relative',
          },
        }}
      >
        <DialogContent style={{ padding: 0, backgroundColor: 'transparent' }}>
          <a-scene embedded>
            <a-assets>
              <a-asset-item id="house-model" src={vrWithBaseUrl}></a-asset-item>
            </a-assets>
            <a-camera position="0 1.6 0"></a-camera>
            <a-entity gltf-model="#house-model" position="0 0 0" scale="2 2 2"></a-entity>
          </a-scene>
        </DialogContent>
        <IconButton
          style={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1300,
          }}
          onClick={handleDialogClose}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Snackbar
  open={notificationOpen}
  autoHideDuration={3000} // Adjust the duration here
  onClose={handleNotificationClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  <Alert onClose={handleNotificationClose} severity="error" sx={{ marginTop: '160px', width: '100%', backgroundColor: 'white', color: 'red' }}>
    360Video or VR not available
  </Alert>
</Snackbar>

    </div>
  );
};

export default MyCard;
