// PropertyType.js
import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Config from '../../../Services/Config';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import './PropertyType.css'; 

function PropertyType({ selectedValue, onChange }) {
  const [types, setTypes] = useState([""]);
  const [selectedType, setSelectedType] = useState(selectedValue || null); // Change the default value to null
  const [loading, setLoading] = useState(false);

  const fetchTypes = async () => {
    try {
      const data = await Config.getTypesDetails();

      if (Array.isArray(data)) {
        console.log("data types");
        setTypes(data);
        if (!selectedValue && data.length > 0) {
          setSelectedType('');
        }
      } else {
        console.error('Invalid data structure for types:', data);
      }
    } catch (error) {
      console.error('Error fetching types:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTypes();
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedPropertyType', selectedType || '');
  }, [selectedType]);

  const handleTypeChange = (event) => {
    const newType = event.target.value;
    setSelectedType(newType);
    onChange(newType);
  };

  return (
    <div className="property-type-container">
      <h3 className="pt-h3">Property Type:</h3>
      <div className="toggle-buttons">
      {loading ? (
          <CircularProgress /> // Show progress bar when loading
        ) : (
          <TextField
            id="outlined-select-type"
            select
            label="Select type"
            value={selectedType}
            onChange={handleTypeChange}
            helperText="Please select a type"
            style={{ width: '100%' }}
          >

            {types.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </div>
    </div>
  );
}

export default PropertyType;
