import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import SearchBar from "./SearchBar/SearchBar";
import Card from "./Card/Card";
import Footer from "./Footer/Footer";
import { API_URL } from "./Services/Config";
import {IMAGES_URL} from "./Services/Config";
import NavbarWithObserver from "./NavBar/NavBar";
import ChatWidget from './Components/ChatWidget/index'
import Config from "./Services/Config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';



import "./Ads.css";
import { TextRevealCard, TextRevealCardDescription, TextRevealCardTitle } from "./Components/ui/text-reveal-card";
function Home() {
  const paginationContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    margin: '20px 0',
  };

  const paginationButtonStyle = {
    backgroundColor: '#f0f0f0',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '10px 15px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s',
    fontSize: '16px',
  };

  const activeButtonStyle = {
    backgroundColor: '#bb0129',
    color: 'white',
  };

  const disabledButtonStyle = {
    backgroundColor: '#ddd',
    cursor: 'not-allowed',
  };

  const [cardsData, setCardsData] = useState([]);
  const [cardsDataBuy, setCardsDataBuy] = useState([]);
  const [ads, setAds] = useState([]);
  const [loadingAds, setLoadingAds] = useState(true);
  const [errorAds, setErrorAds] = useState(null);
  const [categories, setCategories] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const [currentPageRent, setCurrentPageRent] = useState(1);
  const [totalPagesRent, setTotalPagesRent] = useState(1);
  const [currentPageBuy, setCurrentPageBuy] = useState(1);
  const [totalPagesBuy, setTotalPagesBuy] = useState(1);
  const itemsPerPage = 20;


  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [filteredCardsBuy, setFilteredCardsBuy] = useState([]);
  const [filteredCardsRent, setFilteredCardsRent] = useState([]);

  // Assume you have a function to filter and set these states
  const filterCards = () => {
    const buyCards = filteredCards.filter(card => card.rent_buy === 'buy');
    const rentCards = filteredCards.filter(card => card.rent_buy === 'rent');
    
    setFilteredCardsBuy(buyCards);
    setFilteredCardsRent(rentCards);
  };


  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [priceRange, setPriceRange] = useState({ min: 0, max: 1000 });
  const [openDialog, setOpenDialog] = React.useState(false);
  const [long, setLongitude] = useState(0);
  const [lat, setLatitude] = useState(0);

  const handleSetOpenDialog = () => {
    
    setOpenDialog(true);
  }
  const handleSetCloseDialog = () => {
    setOpenDialog(false);
  }

  const totalRentPages = useMemo(() => 
    Array.isArray(filteredCards) 
      ? Math.ceil(filteredCards.filter(data => data.rent_buy === 0).length / itemsPerPage) 
      : 0, // or handle the error appropriately
    [filteredCards, itemsPerPage]
  );

  const totalBuyPages = useMemo(() => 
    Array.isArray(filteredCards) 
      ? Math.ceil(filteredCards.filter(data => data.rent_buy === 1).length / itemsPerPage) 
      : 0, // or handle the error appropriately
    [filteredCards, itemsPerPage]
  );

  const handlePrevPageRent = () => setCurrentPageRent(prevPage => Math.max(1, prevPage - 1));
  const handleNextPageRent = () => setCurrentPageRent(prevPage => Math.min(totalRentPages, prevPage + 1));
  const handlePageChangeRent = (page) => setCurrentPageRent(page);

  const handlePrevPageBuy = () => setCurrentPageBuy(prevPage => Math.max(1, prevPage - 1));
  const handleNextPageBuy = () => setCurrentPageBuy(prevPage => Math.min(totalBuyPages, prevPage + 1));
  const handlePageChangeBuy = (page) => setCurrentPageBuy(page);


  //to get the current location via public ip
  useEffect(() => {
    const fetchHomeCardDetails = async () => {
        try {
          const response = await fetch('https://ipapi.co/json/');
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setLongitude(data.longitude);
          setLatitude(data.latitude);
        } catch (error) {
          console.error('Error fetching location:', error);
          // Set default values on error
          setLongitude(35.5833);
          setLatitude(33.95);
        }

    }
    
    setLoading(true);
    fetchHomeCardDetails()
  }, []);

  useEffect(() => {
    const getAllProperties = async () => {
      try {
        console.log("longitude", long);
        console.log("latitude", lat);
        const data = await Config.getHomeCardDetails(long, lat);
        console.log("dataaaaaaaaaaaaaaa", data);
  
        
  
        if (data.rent) {
          const sortedRentData = data.rent;
          setCardsData(sortedRentData);
          setFilteredCards(sortedRentData);
          setCardsDataBuy([]);
          setFilteredCardsBuy([]);
        } else if (data.buy) {
          const sortedBuyData = data.buy;
          setCardsDataBuy(sortedBuyData);
          setFilteredCardsBuy(sortedBuyData);
          setCardsData([]);
          setFilteredCards([]);
        }
        
        
        setLoading(false);
      } catch (exception) {
        console.error(exception);
        setLoading(false);
      }
    };
  
    if (long !== 0 && lat !== 0) {
      getAllProperties();
    }
  }, [long, lat]);
  
  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await axios.get(`${API_URL}/getads`);

        
        console.log(response.data);
        if (Array.isArray(response.data.data)) {
          setAds(response.data.data);
        } else {
          setAds([]);
        }
        setLoadingAds(false);
      } catch (error) {
        setErrorAds(error);
        setLoadingAds(false);
      }
    };

    fetchAds();
  }, []);
  const filterAdsByDate = (ads) => {
    const dateCounts = { startDates: {}, endDates: {} };
  
    return ads.filter(ad => {
      const startDate = ad.start_date.split('T')[0];
      const endDate = ad.end_date.split('T')[0];
  
      // Initialize count if not already present
      if (!dateCounts.startDates[startDate]) {
        dateCounts.startDates[startDate] = 0;
      }
      if (!dateCounts.endDates[endDate]) {
        dateCounts.endDates[endDate] = 0;
      }
  
      const canIncludeStartDate = dateCounts.startDates[startDate] < 2;
      const canIncludeEndDate = dateCounts.endDates[endDate] < 2;
  
      // Increment count if the ad is valid
      if (canIncludeStartDate && canIncludeEndDate) {
        dateCounts.startDates[startDate]++;
        dateCounts.endDates[endDate]++;
        return true;
      }
      return false;
    });
  };
  

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/categories`
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);


  const handleSearch = async ({ location, checkIn, checkOut, guests, minPrice, maxPrice, selectedCategory, maxRange, selectedPropertyType, selectedBedroom, selectedBeds, selectedBathroom, long, lat, selectedButton }) => {
    try {
      // Handle null checkIn and checkOut values
      if (checkIn == null) {
        checkIn = "";
        checkOut = "";
      }
  
      // Fetch the property details
     
      const response = await Config.getHomeCardDetails(long, lat, selectedButton,checkIn,checkOut,minPrice,maxPrice,0,maxRange,selectedCategory,selectedPropertyType,selectedBedroom,selectedBeds,selectedBathroom);
      console.log("response search button : ", response);
  
      
  
      if (response.rent) {
        console.log("rent!");

        // const sortedRentData = response.rent.sort(sortByPeriority);
        const sortedRentData = response.rent;
        console.log(sortedRentData);
        setCardsData(sortedRentData);
        console.log(cardsData);
        
         
        setFilteredCards(sortedRentData);
        setCardsDataBuy([]);
        setFilteredCardsBuy([]);
      } else if (response.buy) {
        console.log("buy!");

        // const sortedBuyData = response.buy.sort(sortByPeriority);
        const sortedBuyData = response.buy;
        setCardsDataBuy(sortedBuyData);
        setFilteredCardsBuy(sortedBuyData);
        setCardsData([]);
        setFilteredCards([]);
      } else if (response.all) {
        console.log("all!");

        // const sortedRentData = response.all.rent.sort(sortByPeriority);
        // const sortedBuyData = response.all.buy.sort(sortByPeriority);
        const sortedRentData = response.all.rent;
        const sortedBuyData = response.all.buy;
        setCardsData(sortedRentData);
        setFilteredCards(sortedRentData);
        setCardsDataBuy(sortedBuyData);
        setFilteredCardsBuy(sortedBuyData);
      } else {
        console.log("HERE!");
        
      }
      console.log("finish!");
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false); // Ensure loading is stopped in case of an error
    }
  };
  

  const applyFilters = () => {
    console.log("cardsData before filtering:", cardsData); // Check the data
    const cardsArray = Object.values(cardsData);

    if (!Array.isArray(cardsArray)) {
      console.error("cardsData is not an array", cardsArray);
      return;
    }
    const filteredData = cardsArray.filter((data) => {
      const lowercasedDataLocation = data.location.toLowerCase().trim();
      const includesCheck = lowercasedDataLocation.includes(
        searchInput.toLowerCase()
      );
      const priceCheck =
        data.price >= parseInt(priceRange.min, 10) &&
        data.price <= parseInt(priceRange.max, 10);

      return includesCheck && priceCheck;
    });

    setFilteredCards(filteredData);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    applyFilters();
  }, [searchInput, cardsData]);

  



  return (
    <div className="App">
   

      <NavbarWithObserver openDialog={openDialog} handleSetOpenDialog={handleSetOpenDialog} handleSetCloseDialog={handleSetCloseDialog} />

      <SearchBar onSearch={handleSearch}  />
      {loading ? (
  <div>Loading...</div>
) : error ? (
  <div>Error: {error.message}</div>
) : (
  <>
   {filteredCards.length > 0 && filteredCards.some(card => card.rent_buy === 0) && (
  <>
    <h2 style={{ paddingBottom: '10px', marginBottom: '20px' }}>
    </h2>
    <div className="card-grid">
      {filteredCards
        .filter(data => data.rent_buy === 0)
        .slice((currentPageRent - 1) * itemsPerPage, currentPageRent * itemsPerPage)
        .map(data => (
          <div key={data.id} style={{ marginTop: "2%" }}>
            <Card
              id={data.id}
              name={data.name}
              rent_buy={data.rent_buy}
              price={data.price}
              location={data.location}
              available_at={data.available_at}
              availability_ends_at={data.availability_ends_at}
              distance={data.distance}
              property_images={data.property_images}
              vr_url={data.vr_url}
              image360_url={data.image360_url}
              sponsorr={data.sponsorr}
            />
          </div>
        ))}
    </div>
    <div style={paginationContainerStyle}>
      <button
        style={{ ...paginationButtonStyle, ...(currentPageRent === 1 ? disabledButtonStyle : {}) }}
        onClick={handlePrevPageRent}
        disabled={currentPageRent === 1}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      {Array.from({ length: totalRentPages }, (_, index) => (
        <button
          key={index + 1}
          style={{
            ...paginationButtonStyle,
            ...(index + 1 === currentPageRent ? activeButtonStyle : {}),
          }}
          onClick={() => handlePageChangeRent(index + 1)}
        >
          {index + 1}
        </button>
      ))}
      <button
        style={{ ...paginationButtonStyle, ...(currentPageRent === totalRentPages ? disabledButtonStyle : {}) }}
        onClick={handleNextPageRent}
        disabled={currentPageRent === totalRentPages}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  </>
)}

{filteredCards.length > 0 && filteredCards.some(card => card.rent_buy === 1) && (
  <>
    <h2 style={{ paddingBottom: '10px', marginBottom: '20px' }}>
    </h2>
    <div className="card-grid">
      {filteredCards
        .filter(data => data.rent_buy === 1)
        .slice((currentPageBuy - 1) * itemsPerPage, currentPageBuy * itemsPerPage)
        .map(data => (
          <div key={data.id} style={{ marginTop: "2%" }}>
            <Card
              id={data.id}
              name={data.name}
              rent_buy={data.rent_buy}
              price={data.price}
              location={data.location}
              available_at={data.available_at}
              availability_ends_at={data.availability_ends_at}
              distance={data.distance}
              property_images={data.property_images}
              vr_url={data.vr_url}
              image360_url={data.image360_url}
              sponsorr={data.sponsorr}
            />
          </div>
        ))}
    </div>
    <div style={paginationContainerStyle}>
      <button
        style={{ ...paginationButtonStyle, ...(currentPageBuy === 1 ? disabledButtonStyle : {}) }}
        onClick={handlePrevPageBuy}
        disabled={currentPageBuy === 1}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      {Array.from({ length: totalBuyPages }, (_, index) => (
        <button
          key={index + 1}
          style={{
            ...paginationButtonStyle,
            ...(index + 1 === currentPageBuy ? activeButtonStyle : {}),
          }}
          onClick={() => handlePageChangeBuy(index + 1)}
        >
          {index + 1}
        </button>
      ))}
      <button
        style={{ ...paginationButtonStyle, ...(currentPageBuy === totalBuyPages ? disabledButtonStyle : {}) }}
        onClick={handleNextPageBuy}
        disabled={currentPageBuy === totalBuyPages}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  </>
)}

  </>
)}



<br/>





<br/>

{/* <div style={{ display: 'flex', justifyContent: 'center' }}>
  <img src={`${process.env.PUBLIC_URL}/ad1.jpg`} alt="Day Image" style={{ width: '45%', height: '250px', margin: '10px' }} />
  <img src={`${process.env.PUBLIC_URL}/ad2.jpg`} alt="Day Image" style={{ width: '45%', height: '250px', margin: '10px' }} />
</div> */}
  {loadingAds ? (
      <div>Loading Ads...</div>
    ) : errorAds ? (
      <div>Error: {errorAds.message}</div>
    ) : ads.length > 0 ? (
      <div className="ads" 
          
      >
        {filterAdsByDate(ads).map(ad => (
          <div 
            key={ad.id} 
            className="ad-item" 
            style={{
              marginBottom: '20px',
              width: '100%', // Set the ad item width to 90% of the parent container
              display: 'flex', // Use flex to center the image if needed
              justifyContent: 'center', // Center the image horizontally
            }}
            
          >
            <img 
              src={`${IMAGES_URL}${ad.image_url}`} 
              alt={`Ad ${ad.id}`} 
              style={{ width: '90%', height: '250px', objectFit: 'cover' }} // Smaller image size
            />
          </div>
        ))}
      </div>
    ) : null}


 {/* {loadingAds ? (
        <div>Loading Ads...</div>
      ) : errorAds ? (
        <div>Error: {errorAds.message}</div>
      ) : ads.length > 0 ? (
        <div className="ads" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {filterAdsByDate(ads).map(ad => (
            <div 
              key={ad.id} 
              className="ad-item" 
              style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}
            >
              <img 
                src={`${IMAGES_URL}${ad.image_url}`} 
                alt={`Ad ${ad.id}`} 
                style={{ width: '750px', height: '250px', objectFit: 'cover' }} 
              />
            </div>
          ))}
        </div>
      ) : null} */}
      <ChatWidget />
      <Footer />
    </div>
  );
}

export default Home;
