import {
  CameraControls,
  ContactShadows,
  Environment,
  Text,
} from "@react-three/drei";
import { Suspense, useEffect, useRef, useState } from "react";
import { Avatar } from "./Avatar";

export const Experience = ({greet,latestMsg}) => {
  const cameraControls = useRef();
  const [enableControlls,setEnableControlls] = useState(true);
  const [isAvatarLoaded, setIsAvatarLoaded] = useState(false);
  const timestamp = new Date().getMinutes() ;
  console.log("Experience timestamp" , timestamp);
  


  useEffect(() => {
    cameraControls.current.setLookAt(0, 2, 5, 0, 1.5, 0);
    // cameraControls.current.setLookAt(0,2,5,0,1.5,0);
    setEnableControlls(false)
  }, []);



  return (
    <>
      <CameraControls ref={cameraControls} enabled={enableControlls}/>
      <Environment preset="sunset" />
      {/* Wrapping Dots into Suspense to prevent Blink when Troika/Font is loaded */}
      {/* <Suspense>
      </Suspense> */}
      {/* <Suspense fallback={null}>
        <Avatar greet={greet} latestMsg={latestMsg} onLoad={() => setIsAvatarLoaded(true)} position={[0, 0, 0]} cameraControls={cameraControls}  renderOrder={1} />
      </Suspense> */}
      {/* <Avatar greet={greet} latestMsg={latestMsg} onLoad={() => setIsAvatarLoaded(true)} position={[0, 0, 0]} cameraControls={cameraControls}  renderOrder={1} /> */}

      <Avatar greet={greet} latestMsg={latestMsg}  cameraControls={cameraControls}   />
      
      <ContactShadows opacity={0.7} />
    </>
  );
};
