import React, { useState, useEffect, useDebugValue } from "react";
import Config from "../../../Services/Config";
import Navbar from "../../../NavBar/NavBar";
import { useLocation } from 'react-router-dom';
import SnackbarComponent from "../../../SnackBar/SnackBar";
import { Card, CardContent, Typography, Box, Grid, Button, Select, MenuItem, InputLabel, FormControl, CardMedia, Divider } from '@mui/material';
import { useAuth } from "../../../AuthContext";
import LoggedOut from "../../../Components/Reusables/LoggedOut/LoggedOut"
import { Token } from "@mui/icons-material";
import { IMAGES_URL } from "../../../Services/Config";
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router for navigation

function ReservationPayment() {
  const location = useLocation();
  const [reservationDetails, setReservationDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedCard, setSelectedCard] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { isAuthenticated, logout } = useAuth();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const navigate = useNavigate();

  const reservationData = {
    "reservation_start": location.state?.checkIn || null,
    "reservation_end": location.state?.checkOut || null,
    "property_id": location.state?.propertyId
  };

  const handleCardChange = (event) => {
    setSelectedCard(event.target.value);
  };
  
  useEffect(() => {
    const handleLoading = () => {
      setIsLoading(true);
    };
    handleLoading();
  }, []);


  useEffect(() => {
    if(reservationDetails !== null){
      setIsLoading(false);
    }    
  },[reservationDetails]);


  useEffect(() => {
    const fetchReservationDetails = async () => {
      try {
        const result = await Config.makeReceipt(reservationData);
        setReservationDetails(result);
      } catch (error) {
        setError("Failed to fetch reservation details");
      } finally {
        setIsLoading(false);
      }
    };





    const fetchPaymentMethods = async () => {
      try {
        const user = await Config.getUserDetails();
        if (user.data.id) {
          const id = user.data.id;
        const data = await Config.getPaymentMethods(id);
        console.log("paymnent method : ",data)
        setPaymentMethods(data);
        if (data.data.length > 0) {
          setSelectedCard(data.data[0].last_four_digit);

        }
      }
      } catch (error) {
        setError("Failed to fetch payment methods");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPaymentMethods();
    fetchReservationDetails();
    setIsLoading(false);
  }, [location.state]);


  async function handleConfirmAndPay() {
    const reservationPaymentData = {
      ...reservationData,
      // payment_id: selectedCard,
      payment_id: 1,
    };

    // console.log(reservationPaymentData);

    try {
      // setLoading(true);
      setIsLoading(true);
      const result = await Config.makeReservation(reservationPaymentData, {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json', // Specify the content type
        },
      });

      console.log("Result reservation",result);
      setIsLoading(false);
      if (result.success) {
        setOpenSnackbar(true);
        setSnackbarMessage(result.message);
        setSnackbarSeverity("success");
        setTimeout(() => {
          window.location.href = "/";
        }, 3000);
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage(result.message);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setError("Failed to fetch payment methods");
    } finally {
      setIsLoading(false);
    }
  }

const formatExpiryDate = (expiryDate) => {
  const date = new Date(expiryDate);
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month and pad with zero if needed
  const year = date.getFullYear();
  return `${month} ${year}`;
};

  return (
    <>
    {(isLoading && reservationDetails === null) ? (
                <div className="loading-overlay">
                  <div className="loader-icon">Loading ...</div>
                </div>
    ) : (
        (isLoading && reservationDetails !== null) ? (

        
      <div>
                <div className="loading-overlay">
                  <div className="loader-icon">Loading ...</div>
                </div>
      <div className="myContainer">
        <div className="myContainer-item">
          <Grid container spacing={2}>
            <Grid item sx={{ margin: 2, marginBottom: 0 }} xs={12} sm={12} md={12}>
              <Typography variant="h3" style={{ textAlign: "center", fontFamily: "Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif" }}>Confirm and Pay</Typography>
              {reservationDetails && (
                <Card sx={{ maxWidth: 600, mx: 'auto', mt: 5, mb: 5, boxShadow: 2 }}>
                  <CardMedia
                    component="img"
                    height="300"
                    image={`${IMAGES_URL}${reservationDetails.property.first_image}`}
                    alt={reservationDetails.property.name}
                  />

                  
                  <CardContent>

                    <Typography variant="h4">Your stay</Typography><br /><br />
                    <Typography variant="h5" style={{marginBottom: "10px", fontWeight: "500"}}>Reserved Dates</Typography>
                    {reservationDetails.property.rent_buy === 0 && reservationData.reservation_start && reservationData.reservation_end && (
  <Typography>
    Start Date: {new Date(reservationData.reservation_start).toLocaleDateString()}<br />
    End Date: {new Date(reservationData.reservation_end).toLocaleDateString()}
  </Typography>
)}

<br /><br />

                    <Typography variant="h5" style={{marginBottom: "10px", fontWeight: "500"}}>Guests</Typography>

                    <Divider sx={{ my: 2 }} />

                    <Typography gutterBottom variant="h4" component="div">
                      {reservationDetails.property.name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      Location: {reservationDetails.property.location}
                    </Typography>
                    <Box mt={2}>
                      <Grid container spacing={1}>
                        <Grid item xs={10}>
                          <Typography style={{ fontSize: "1.3rem" }}>Price per day:</Typography>
                          <Typography style={{ fontSize: "1.3rem" }}>Days:</Typography>
                          {/* <br/> */}
                          <Typography style={{ fontSize: "1.3rem" }}>Subtotal:</Typography>
                          <br />
                          <Typography style={{ fontSize: "1.3rem" }}>Fees:</Typography>
                          <Typography style={{ fontSize: "1.3rem" }}>System Fee:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography style={{ fontSize: "1.3rem" }}>${reservationDetails.dailyPrice}</Typography>
                          <Typography style={{ fontSize: "1.3rem" }}>x {reservationDetails.days}</Typography>
                          <Divider sx={{ width: '50%' }}></Divider>
                          <Typography style={{ fontSize: "1.3rem" }}>${reservationDetails.price}</Typography>
                          <br />
                          <Typography style={{ fontSize: "1.3rem" }}>${reservationDetails.fees}</Typography>
                          <Typography style={{ fontSize: "1.3rem" }}>${reservationDetails.systemFee}</Typography>
                        </Grid>
                      </Grid>
                      <Divider sx={{ my: 2 }} />
                      <Grid container spacing={1}>
                        <Grid item xs={10}>
                          <Typography style={{ fontSize: "1.3rem", textDecoration: "underline" }} sx={{ fontWeight: 'bold' }}>Total:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography style={{ fontSize: "1.3rem", textDecoration: "underline" }} sx={{ fontWeight: 'bold' }}>${reservationDetails.total}</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              )}

              {/* Payment Method Selection */}
              <Card sx={{ maxWidth: 600, mx: 'auto', mt: 5, mb: 5, boxShadow: 2 }}>
                <CardContent>
                  <Typography sx={{ mb: 2 }} gutterBottom variant="h5" component="div">
                    Select a Payment Method
                  </Typography>
                  <FormControl fullWidth>
                    {/* <InputLabel id="payment-method-label">Card</InputLabel> */}
                    <Select
                      labelId="payment-method-label"
                      id="payment-method-select"
                      value={selectedCard}
                      // label="Card"
                      onChange={handleCardChange}
                    >
                      
                      {paymentMethods.length > 0 ? (
                          paymentMethods.map((method) => (
                            <MenuItem key={method.id} value={method.id}>
                              {`**** **** **** ${method.last_four_digit} - Expires ${method.card.exp_month}/${method.card.exp_year}`}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No payment methods available</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  {/* Add New Payment Method Button */}
                  <Button 
                    variant="contained" 
                    color="primary" 
                    sx={{ mt: 3 }}
                    onClick={() => navigate('/billing')} // Navigate to billing page on click
                  >
                    Add New Payment Method
                  </Button>
                </CardContent>
              </Card>

              {/* Payment Confirmation Button */}
              <Box sx={{ textAlign: 'end', mt: 3, mb: 3 }}>
                <Button onClick={handleConfirmAndPay} variant="contained" size="large" style={{backgroundColor: "blue"}}>
                  Confirm and Pay
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>


      <SnackbarComponent
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

    </div>

    ) : (
      (!isLoading && reservationDetails !== null)  ? (
        <div>
      <Navbar></Navbar>
      <div className="myContainer">
        <div className="myContainer-item">
          <Grid container spacing={2}>
            <Grid item sx={{ margin: 2, marginBottom: 0 }} xs={12} sm={12} md={12}>
              <Typography variant="h3" style={{ textAlign: "center", fontFamily: "Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif" }}>Confirm and Pay</Typography>
              {reservationDetails && (
                <Card sx={{ maxWidth: 600, mx: 'auto', mt: 5, mb: 5, boxShadow: 2 }}>
                  <CardMedia
                    component="img"
                    height="300"
                    image={`${IMAGES_URL}${reservationDetails.property.first_image}`}
                    alt={reservationDetails.property.name}
                  />

                  
<CardContent>
  <Typography variant="h4">Your stay</Typography><br /><br />

  {reservationDetails?.property?.rent_buy === 0 && reservationData?.reservation_start && reservationData?.reservation_end && (
    <>
      <Typography variant="h5" style={{ marginBottom: "10px", fontWeight: "500" }}>Reserved Dates</Typography>
      <Typography>
        Start Date: {new Date(reservationData.reservation_start).toLocaleDateString()}<br />
        End Date: {new Date(reservationData.reservation_end).toLocaleDateString()}
      </Typography><br /><br />
    </>
  )}

  <Typography variant="h5" style={{ marginBottom: "10px", fontWeight: "500" }}>Guests</Typography>
  <Divider sx={{ my: 2 }} />

  <Typography gutterBottom variant="h4" component="div">
    {reservationDetails?.property?.name || "Property Name"}
  </Typography>
  <Typography variant="body1" color="text.secondary">
    Location: {reservationDetails?.property?.location || "Property Location"}
  </Typography>

  <Box mt={2}>
    <Grid container spacing={1}>
      <Grid item xs={10}>
        <Typography style={{ fontSize: "1.3rem" }}>Price per day:</Typography>
        <Typography style={{ fontSize: "1.3rem" }}>Days:</Typography>
        <Typography style={{ fontSize: "1.3rem" }}>Subtotal:</Typography>
        <br />
        <Typography style={{ fontSize: "1.3rem" }}>Fees:</Typography>
        <Typography style={{ fontSize: "1.3rem" }}>System Fee:</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography style={{ fontSize: "1.3rem" }}>${reservationDetails?.dailyPrice || "0"}</Typography>
        <Typography style={{ fontSize: "1.3rem" }}>x {reservationDetails?.days || "0"}</Typography>
        <Divider sx={{ width: '50%' }}></Divider>
        <Typography style={{ fontSize: "1.3rem" }}>${reservationDetails?.price || "0"}</Typography>
        <br />
        <Typography style={{ fontSize: "1.3rem" }}>${reservationDetails?.fees || "0"}</Typography>
        <Typography style={{ fontSize: "1.3rem" }}>${reservationDetails?.systemFee || "0"}</Typography>
      </Grid>
    </Grid>

    <Divider sx={{ my: 2 }} />

    <Grid container spacing={1}>
      <Grid item xs={10}>
        <Typography style={{ fontSize: "1.3rem", textDecoration: "underline" }} sx={{ fontWeight: 'bold' }}>Total:</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography style={{ fontSize: "1.3rem", textDecoration: "underline" }} sx={{ fontWeight: 'bold' }}>${reservationDetails?.total || "0"}</Typography>
      </Grid>
    </Grid>
  </Box>
</CardContent>

                </Card>
              )}

              {/* Payment Method Selection */}
              <Card sx={{ maxWidth: 600, mx: 'auto', mt: 5, mb: 5, boxShadow: 2 }}>
                <CardContent>
                  <Typography sx={{ mb: 2 }} gutterBottom variant="h5" component="div">
                    Select a Payment Method
                  </Typography>
                  <FormControl fullWidth>
                    {/* <InputLabel id="payment-method-label">Card</InputLabel> */}
                    <Select
                      labelId="payment-method-label"
                      id="payment-method-select"
                      value={selectedCard}
                      // label="Card"
                      onChange={handleCardChange}
                    >
                      {/* {paymentMethods.map((method) => (
                        <MenuItem key={method.id} value={method.id}>
                          {`**** **** **** ${method.last_four_digits} - Expiry Date: ${formatExpiryDate(method.expiry_date)}`}
                        </MenuItem>
                      ))} */}
                      {paymentMethods.length > 0 ? (
                          paymentMethods.map((method) => (
                            <MenuItem key={method.id} value={method.id}>
                              {`**** **** **** ${method.last_four_digits} - Expiry Date: ${formatExpiryDate(method.expiry_date)}`}
                          </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No payment methods available</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    sx={{ mt: 3 }}
                    onClick={() => navigate('/billing')} // Navigate to billing page on click
                  >
                    Add New Payment Method
                  </Button>
                </CardContent>
              </Card>

              {/* Payment Confirmation Button */}
              <Box sx={{ textAlign: 'end', mt: 3, mb: 3 }}>
                <Button onClick={handleConfirmAndPay} variant="contained" size="large" style={{backgroundColor: "blue"}}>
                  Confirm and Pay
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>


      <SnackbarComponent
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

    </div>
      ) : (

        (!isLoading && reservationDetails === null) ? (
          <div className="loading-overlay">
                  <div className="loader-icon">Loading ...</div>
                </div>
        ) : null
      )


    )
      
    )}
    </>
  );
}

export default ReservationPayment;
