// importing external style
import { BsTextWrap } from "react-icons/bs";
import { styles } from "./../styles";
import { useState,useEffect,useRef } from 'react';
import React from 'react';
import '@babel/polyfill';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
//for displaying the model view/Window
import axios from "axios";
import { Result } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons'; 
import { faRobot } from '@fortawesome/free-solid-svg-icons'; // Icon for bot
import { faUser } from '@fortawesome/free-solid-svg-icons'; // Icon for user
import Config, { PYTHON_URL } from "../../../Services/Config";




function ModalWindow(props) {
    let voices = [];
    window.speechSynthesis.onvoiceschanged = () => {
         voices = speechSynthesis.getVoices();
        // Optionally, store voices for later use
    };
    const [msgs, setMsgs] = useState([{sender: 0, message:'hello'}]);
    const { transcript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
    const [isListening, setIsListening] = useState(false);
    let [text,setText]=useState('');
    const [audiotext,setaudiotext]=useState ('Audio');
    let { setLatestMsg, latestMsg } = props;
    const [audioUrl, setAudioUrl] = useState('');
    const msgContainerRef = useRef(null);
    const buttonStyle = {
       padding:'10px 6px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        backgroundColor: '#ffffff',
        cursor: 'pointer',
        outline: 'none',
      };
      
      function ModalWindow(props) {
          // Component code...
      }
      useEffect(() => {
        if(latestMsg!=""){
            text=latestMsg
            handleClick();

        console.log('Latest Message:', latestMsg);
        latestMsg='';
        setText('')
        }
        // Perform actions with latestMsg, such as fetching data, updating state, etc.
      }, [latestMsg]);
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            props.stopListening()
            handleClick();
            
         
        }
    };

    const handleClick = async () => {
        if (text !== '') {
            // Add the text to msgs state
            setMsgs(prevMsgs => [...prevMsgs, { sender: 1, message: text }]);
            // send the text to the python server
            handleMessageSubmit();
            // Clear the text
            setText('');
        }
    };
    // let availableVoices = [];

    // // Load voices when they change
    // speechSynthesis.onvoiceschanged = () => {
    //     console.log("check");
        
    //     availableVoices = speechSynthesis.getVoices();
    //     console.log(availableVoices);
        
    // };
    const handleMessageSubmit = async () => {
        try {
            console.log("Will send heree");
            // const response = await fetch(`http://127.0.0.1:5000/answer/${encodeURIComponent(text)}`, {
                
 
            const response = await fetch(`${PYTHON_URL}answer/${encodeURIComponent(text)}`, {

                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
    
            if (!response.ok) {
                console.log(`HTTP error! Status: ${response.status}`);

                throw new Error(`HTTP error! Status: ${response.status}`);
            }


            const resultText = await response.text();
            const responseData = JSON.parse(resultText);
            console.log('Response Data:', responseData);

            // Select a random response from the server's response array
            const responses = responseData.responses;
            // const randomIndex = Math.floor(Math.random() * responses.length);
            let messageToSend = responses;
            if (messageToSend===undefined || messageToSend===''){
                messageToSend="I can't understand your question"
            }
            // Speak the response
            if (messageToSend !== "I can't understand your question") {
                let utterance = new SpeechSynthesisUtterance(messageToSend);
                let voices = speechSynthesis.getVoices();
                console.log(voices);
                
                // let selectedVoice = voices.find(voice => voice.voiceURI === 'Microsoft Aria Online (Natural) - English (United States)'); 
                let selectedVoice = voices.find(voice => voice.voiceURI === "Microsoft Zira - English (United States)"); 
                // Fallback to the first voice if the specific one isn't found
                console.log(selectedVoice);
                if (!selectedVoice) {
                    console.warn("Selected voice not found. Falling back to the first available voice.");
                    selectedVoice = voices[0];
                }
               
                console.log(selectedVoice);
                if (selectedVoice) {
                    console.log("Using voice:", selectedVoice.name);
                    speechSynthesis.cancel();
                    utterance.voice = selectedVoice;
                    props.stopListening1();
                    speechSynthesis.speak(utterance);
                    utterance.onend=function(event){
                        props.startListening();
                    }
                } else {
                    console.error('Voice not found.');
                }
            }
            // Update msgs state with the response
            setMsgs(prevMsgs => [...prevMsgs, { sender: 0, message: messageToSend }]);

            props.setLatestMsg(text)
            setText('')
        }catch (error) {
            console.error('Error:', error);
        }
    };
    
     useEffect(() => {
        if (props.latestMsg == "") {
            setText('');
            props.setLatestMsg("");
        }
    }, [props.latestMsg]);
    
    
   /////////////////////////////////////////////////////////////////////////////

   useEffect(() => {
    // Update the audio when audioUrl changes
    console.log('hihi')
    if (audioUrl) {
      const audioElement = document.getElementById('audioPlayer');
      audioElement.load();
    }
  }, [audioUrl]);
   
    useEffect(() => {
        let silenceTimer;
        const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    
        // Check if the browser language is set to Arabic
        const isArabic = navigator.language.startsWith("ar");
      
       
        const startListeningArabic = () => {
          SpeechRecognition.startListening({ continuous: true, language: 'ar-SA' });
      };
      const startListeningEnglish = () => {
          SpeechRecognition.startListening({ continuous: true, language: 'en-US' });
      };
        if (isListening) {
           
                //startListeningArabic();
            
                startListeningEnglish();
            silenceTimer = setTimeout(() => {
                setIsListening(false);
                SpeechRecognition.stopListening();
                let messagetext = transcript.replace(/\./g, '');
                setText(messagetext);
                setLatestMsg(messagetext);
                console.log("the transcript: " + messagetext)
                
                setaudiotext('Audio');
            }, 2000);
        } else {
            SpeechRecognition.stopListening();
            handleClick();
            clearTimeout(silenceTimer);
        }
        return () => {
            clearTimeout(silenceTimer);
        };
    }, [ isListening, transcript]);
    const startListening = () => {
        setaudiotext('speak!');
        setIsListening(true);
        resetTranscript();
    };

    useEffect(() => {
        // Scroll to bottom of messages container whenever msgs state updates
        if (msgContainerRef.current) {
            msgContainerRef.current.scrollTop = msgContainerRef.current.scrollHeight;
        }
    }, [msgs]);

    
    if (!browserSupportsSpeechRecognition) {
        return null;
    }


    
    return (
        <div
        style={{
            ...styles.modalWindow,
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
            position: 'fixed',
            overflow: 'hidden',
            display: props.visible ? 'flex' : 'none', // Display the modal only if visible
            flexDirection: 'column',
            zIndex: 1000, // Ensure modal is above other content
        }}
    >
          <div style={{ backgroundColor: '#bb0129', padding: '5px', textAlign: 'center', fontWeight: 'bold', color: 'white' }}>AI ROBOT AND CHATBOT</div>
    <div style={{ display: 'flex', backgroundColor: '#bb0129', paddingLeft: '120px', textAlign: 'right', color: 'white', fontSize: '10px' }}>
         <div style={{ flex: '1', textAlign: 'right', paddingRight: '5px', fontSize: '10px' }}>POWERED BY</div>
        <div style={{ flex: '1', textAlign: 'left', fontWeight: 'bold', fontSize: '10px' }}>EDDY'S GROUP</div>
    </div>

            <div ref={msgContainerRef} style={{ overflowY: 'auto', maxHeight: '30vh' }}>
                {msgs.map((msg, index) => (
                    <div key={index} style={{ display: 'flex', justifyContent: msg.sender === 1 ? 'flex-end' : 'flex-start', alignItems: 'flex-start', marginTop: '10px' }}>
                        {msg.sender === 0 ? (
                            <div style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                                <FontAwesomeIcon icon={faRobot} style={{ fontSize: '24px', padding: '5px', color: '#333' }} />
                                <p style={{ backgroundColor: '#f0f0f0', color: '#333', padding: '10px', borderRadius: '12px', maxWidth: '70%' }}>{msg.message}</p>
                            </div>
                        ) : (
                            <div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                                <p style={{ backgroundColor: '#bb0129', color: 'white', padding: '10px', borderRadius: '12px', maxWidth: '70%' }}>{msg.message}</p>
                                <FontAwesomeIcon icon={faUser} style={{ fontSize: '24px', padding: '5px', color: 'red' }} />
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div style={{ position: 'absolute', bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', width: '100%', padding: '10px' }}>
                <input
                    type='text'
                    style={{
                        width: '190px',
                        height: '30px',
                        padding: '5px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        fontSize: '16px',
                        outline: 'none',
                    }}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <button onClick={handleClick} style={{backgroundColor:'green',color:'white',padding: '10px 10px', border: 'none',borderRadius: '5px',fontSize:'10px',}}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                </button>
                <button
    onClick={() => {
        if (props.recording) {
            props.stopListening1();
        } else {
            props.startListening();
        }
    }}
    style={{
        backgroundColor: props.recording ? 'red' : 'green',
        color: 'white',
        border: 'none',
        padding: '10px 10px',
        borderRadius: '5px',
        cursor: 'pointer',
        outline: 'none',
        fontSize:'11px',
    }}
>
  
    <FontAwesomeIcon icon={faMicrophone} />
</button>
            </div>
            {audioUrl && (
                <audio id="audioPlayer" autoPlay>
                    <source src={require(`${audioUrl}`)} type="audio/mpeg" />
                </audio>
            )}
        </div>
    );
}


export default ModalWindow;