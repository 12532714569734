import React, { Suspense, useState, useEffect, useRef } from 'react';
import { BsFillChatFill } from 'react-icons/bs';
import ModalWindow from './ModalWindow';
import { Canvas } from '@react-three/fiber';
import { Experience } from '../Experience';
import annyang from 'annyang';
import { Html, useProgress } from '@react-three/drei'
import Config, { PYTHON_URL } from "../../Services/Config";



function ChatWidget() {
  const [hovered, setHovered] = useState(false);
  const [visible, setVisible] = useState(true);
  const [greet, setGreet] = useState(false);
  const [latestMsg, setLatestMsg] = useState('');
  const [recording, setRecording] = useState(false);
  const [recordedMessages, setRecordedMessages] = useState([]);
  const [showAvatar, setShowAvatar] = useState(true); // New state for avatar

  const widgetRef = useRef(null);
  const recognitionRef = useRef(null);
  const micStateRef = useRef({
    listening: false,
    timeout: null,
  });

  const handleLatestMsgUpdate = (msg) => {
    setLatestMsg(msg);
  };
  function Loader() {
    const { progress } = useProgress()
    return <Html center>{progress} % loaded</Html>
  }
  
  useEffect(() => {
    
    
    recognitionRef.current = annyang;

    if (recognitionRef.current) {
      recognitionRef.current.start();
      recognitionRef.current.addCallback('result', handleSpeechRecognitionResult);
    }

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.abort();
        recognitionRef.current.removeCallback('result', handleSpeechRecognitionResult);
      }
    };
  }, []);

  const handleSpeechRecognitionResult = async (phrases) => {
    const transcript = phrases[0];
    let messagetext = transcript.replace(/\./g, '');
    setLatestMsg(messagetext);

    if (micStateRef.current.timeout) {
      clearTimeout(micStateRef.current.timeout);
    }

    micStateRef.current.timeout = setTimeout(() => {
      stopListening();
    }, 2000);

    try {      
      console.log("Will send");

      // const response = await fetch(`http://127.0.0.1:5000/send/${encodeURIComponent(messagetext)}`, {
      
      const response = await fetch(`${PYTHON_URL}send/${encodeURIComponent(messagetext)}`, {

        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        console.log(`HTTP error! Status: ${response.status}`);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log('Response Data:', responseData);

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const toggleChatWindow = () => {
    // setVisible(!visible);
    // setGreet(!greet);
    // if (!recording) {
    //   startListening();
    // } else {
    //   stopListening();
    // }
    setVisible((prev) => !prev);
    setGreet((prev) => !prev);
    if (recording) {
      stopListening();
    } else {
      startListening();
    }
    
  };

  const startListening = () => {
    if (!micStateRef.current.listening) {
      recognitionRef.current.start();
      micStateRef.current.listening = true;
      setRecording(true);
    }
  };

  const stopListening = () => {
    if (micStateRef.current.listening) {
      recognitionRef.current.abort();
      micStateRef.current.listening = false;
      setRecording(false);

      setTimeout(() => {
        startListening();
      }, 2000);
    }
  };

  const stopListening1 = () => {
    if (micStateRef.current.listening) {
      recognitionRef.current.abort();
      micStateRef.current.listening = false;
      setRecording(false);
    }
  };

  const calculateScale = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    const minScale = 0.5;
    const maxScale = 1.5;
    const idealWidth = 1200;
    const idealHeight = 800;

    const newScale = Math.max(
      minScale,
      Math.min(
        maxScale,
        Math.min(width / idealWidth, height / idealHeight)
      )
    );

    return newScale;
  };

  return (
    <div ref={widgetRef}>
      { showAvatar  && (
        <div style={{ position: 'fixed', bottom: 0, right: 0, width: 400, height: 400, zIndex: 1000 }}>
          <Canvas
            shadows
            camera={{ position: [0, 10, 0], fov: 20 }}
            style={{ width: '100%', height: '100%' }}
          >
            <Suspense fallback={<Loader />}>
              <Experience
                greet={greet}
                latestMsg={latestMsg}
                scale={calculateScale()}
              />
            </Suspense>
            
          </Canvas>
        </div>
      )}

      <ModalWindow
        visible={visible}
        setLatestMsg={handleLatestMsgUpdate}
        latestMsg={latestMsg}
        setRecordedMessages={setRecordedMessages}
        startListening={startListening}
        stopListening={stopListening}
        stopListening1={stopListening1}
        recording={recording}
      />

      <div
        onClick={() => {
          toggleChatWindow();
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{
          border: hovered ? '1px solid black' : '',
          backgroundColor: '#bb0129',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
          position: 'fixed',
          bottom: 20,
          right: 20,
          cursor: 'pointer',
          zIndex: 1001, // Ensure the chat button is on top
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <BsFillChatFill size={20} color="white" />
          <span style={{ marginLeft: '5px' }}>Chat Now!!</span>
        </div>
      </div>

      {recordedMessages.length > 0 && (
        <div style={{ position: 'fixed', top: 20, right: 20, backgroundColor: '#fff', padding: '10px', borderRadius: '5px', zIndex: 1000 }}>
          <h2>Recorded Messages:</h2>
          <ul>
            {recordedMessages.map((msg, index) => (
              <li key={index}>{msg}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ChatWidget;
